/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type G94IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function G94Icon(props: G94IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 63 45"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M45.067 10.186c-.294-2.325-.695-4.707-2.13-6.478-.914-.798-2.632-.698-1.849 1.142.754 2.14 2.346 5.147 4.108 6.399l-.129-1.063zM.917 35.676c1.031 1.312 2.245 2.796 3.757 3.534 1.122.664 1.628.025 1.494-1.065.128-3.155.047-6.02-.404-9.073-.04-2.359-4.041-11.962-1.863-13.163 1.285-.713 3.756-.513 3.995 1.276l1.742 7.13c1.208 4.605 3.202 9.392 5.61 13.387 2.64 4.437 4.533 2.011 4.221-2.017-.14-3.393-1.12-7.05-1.712-10.451-.265-2.268-1.592-5.418-1.033-7.283-.286-2.8 3.475-3.259 4.131-.699.81 4.84 1.738 10.054 3.194 14.77.698 2.032 1.491 4.108 2.628 5.754 1.973 2.978 5.232 3.463 4.81-.974-.215-3.46-1.203-7.224-1.731-10.698-.35-2.552-1.349-5.318-.403-7.745 1.504-3.66 6.47-3.447 8.95-.894 1.624-2.733 5.175-2.258 7.201-.183l-.366-2.015C41.857 12.422 33.172 3.008 40.422.241c4.541-1.35 7.542 3.236 7.851 7.216.469 2.006.099 5.234 1.325 6.494a562.45 562.45 0 0012.312 8.851c.07.753.116 1.513.134 2.28l-1.162-.042c-5.654-.403-7.972-5.94-11.604-7.94.71 4.717 1.609 9.42 2.368 14-.14 1.47-2.232.937-3.072.326-.597-.413-1.106-1.019-1.252-1.742-.252-1.04-1.381-6.643-1.627-7.576-1.176-4.662-4.816-4.505-3.677.674l1.075 5.61c.068 1.282 1.308 2.617.26 3.6-4.805 1.854-5.297-7.623-5.765-10.637-.879-1.367-4.127-3.51-4.37-.69.308 3.183 1.173 6.782 1.695 10.07 1.375 4.758 1.732 16.65-6.19 12.917-3.477-1.983-5.197-5.966-6.271-9.884l-.35-.017c.267 2.429.921 5.096.325 7.401-.764 4.213-5.143 3.66-7.468.966-3.517-3.66-5.472-8.8-6.742-13.767.86 4.431 2.705 9.75.968 14.076-1.274 2.243-4.528 1.725-6.193.033-.178-.077-.483-.43-.83-.931a31.824 31.824 0 01-2.116-4.557c-.152-.864.042-1.436.87-1.295z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default G94Icon;
/* prettier-ignore-end */
