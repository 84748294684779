/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Subtract3IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Subtract3Icon(props: Subtract3IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 357 200"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        fillRule={"evenodd"}
        clipRule={"evenodd"}
        d={
          "M139 0H0v192a8 8 0 008 8h341a8 8 0 008-8v-88H178V39.988c-21.629-.531-39-18.23-39-39.988z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default Subtract3Icon;
/* prettier-ignore-end */
