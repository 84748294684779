/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon52IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon52Icon(props: Icon52IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 33 32"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g clipPath={"url(#yudnLY3D83ePa)"}>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M9.334 2a1 1 0 011-1h12a1 1 0 011 1v3h8a1 1 0 011 1v24a1 1 0 01-1 1h-30a1 1 0 01-1-1V6a1 1 0 011-1h8V2zm-7 5v22h28V7h-28zm19-2h-10V3h10v2zm-8 8a1 1 0 011-1h4a1 1 0 011 1v2h2a1 1 0 011 1v4a1 1 0 01-1 1h-2v2a1 1 0 01-1 1h-4a1 1 0 01-1-1v-2h-2a1 1 0 01-1-1v-4a1 1 0 011-1h2v-2zm2 1v2a1 1 0 01-1 1h-2v2h2a1 1 0 011 1v2h2v-2a1 1 0 011-1h2v-2h-2a1 1 0 01-1-1v-2h-2z"
          }
          fill={"#8BCDF3"}
        ></path>
      </g>

      <defs>
        <clipPath id={"yudnLY3D83ePa"}>
          <path
            fill={"#fff"}
            transform={"translate(.333)"}
            d={"M0 0h32v32H0z"}
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon52Icon;
/* prettier-ignore-end */
