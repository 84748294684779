/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type OverlayIconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function OverlayIcon(props: OverlayIconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 40 40"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <path
        d={
          "M0 0h40v33.75l-2.333-1.667-4.875-2.166-4.209-1.292.5-2.375.459-.208.125-.75-.25-.625v-.542l.375-1.75.25-1.583v-.75l-.375-.25-.584.083.334-1.75-.25-3.083-.625-1.334-.375-1.25-.542-1.25-.125-.458-.917-.75-.166-.75-.625-.25-.917-.708-.75-.834-.708-.666L22.208 7l-.75-.333-.166.333-.25-.208L20 6.667v.541l-.292-.125v.375l-.291-.125h-1.125l-.25.292-.334.167.125.375-.583-.625-.625.083v.667l-.917-.125-.125.375-.625.208.5.5-.5.292-.791 1.208-.709 3.042-.541 3.041.208 1.417.667 1.917-.667.375v.375l.167.5v.75l.5.708.375 1.417v.875l-.375.833.625.667.291 1.333-3.625 1.625-1.333.917L9 31.583l-2.458 1.5-3.125 2.625-1.459 1.625-1 2.667H0V0z"
        }
        fill={"currentColor"}
      ></path>
    </svg>
  );
}

export default OverlayIcon;
/* prettier-ignore-end */
