/* eslint-disable */
/* tslint:disable */
// @ts-nocheck
/* prettier-ignore-start */
import React from "react";
import { classNames } from "@plasmicapp/react-web";

export type Icon53IconProps = React.ComponentProps<"svg"> & {
  title?: string;
};

export function Icon53Icon(props: Icon53IconProps) {
  const { className, style, title, ...restProps } = props;
  return (
    <svg
      xmlns={"http://www.w3.org/2000/svg"}
      fill={"none"}
      viewBox={"0 0 33 32"}
      height={"1em"}
      className={classNames("plasmic-default__svg", className)}
      style={style}
      {...restProps}
    >
      {title && <title>{title}</title>}

      <g clipPath={"url(#60N45m2uIfDRa)"}>
        <path
          fillRule={"evenodd"}
          clipRule={"evenodd"}
          d={
            "M6.667 0a1 1 0 011 1v3a1 1 0 01-2 0V3h-2.8a.06.06 0 00-.011 0 .178.178 0 000 .055l2.495 16.168c.12.558.535.877 1.016.877h3.863a.996.996 0 01.873 0h4.764c.481 0 .897-.32 1.016-.88l2.595-16.167a.174.174 0 000-.052.06.06 0 00-.011-.001h-2.8v1a1 1 0 11-2 0V1a1 1 0 112 0h2.8c1.19 0 2.178 1.022 1.99 2.341l-.003.017-2.6 16.2a.955.955 0 01-.007.038c-.286 1.43-1.466 2.504-2.98 2.504h-4.2V30h15v-6.1a5.002 5.002 0 011-9.9 5 5 0 011 9.9V31a1 1 0 01-1 1h-17a1 1 0 01-1-1v-8.9h-3.3c-1.514 0-2.695-1.074-2.98-2.504a.994.994 0 01-.009-.044L.877 3.342C.688 2.021 1.676 1 2.867 1h2.8a1 1 0 011-1zm12.817 3.003zm-16.634 0zM27.667 16a3 3 0 100 6 3 3 0 000-6z"
          }
          fill={"#9E40E7"}
        ></path>
      </g>

      <defs>
        <clipPath id={"60N45m2uIfDRa"}>
          <path
            fill={"#fff"}
            transform={"translate(.667)"}
            d={"M0 0h32v32H0z"}
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon53Icon;
/* prettier-ignore-end */
